/*
 * Copyright 2018 National Association of Insurance Commissioners
 */

// noinspection JSUnusedGlobalSymbols
export const environment = {
  envName: 'int',
  production: false,
  apiUrl: 'https://mcs-service.integration.naic.org/',
  mock: false,
  companyRatiosUrl: 'https://isiteplus.int.naic.org/mis-isite-mcas/companySpecific/mcsCriteria.seam?',
  mcasHelpUrl: 'https://www.naic.org/mcas_main.htm',
  isitePDFUrl: 'https://isiteplus.int.naic.org/mis-isite-mcas/filings/mcasFilingsReport.seam?',
  auth: {
    clientId: '0oa1sz3kjixWOGnDZ0h8',
    issuer: 'https://authenticate-nonprod.naic.org/oauth2/default',
    authorizationEndpoint: 'https://authenticate-nonprod.naic.org/oauth2/default/v1/authorize',
    tokenEndpoint: 'https://authenticate-nonprod.naic.org/oauth2/v1/token',
    redirectBaseUrl: 'https://mcas-test.naic.org',
    logoutUrl: 'https://mcas-test.naic.org',
    userInfoEndpoint: 'https://authenticate-nonprod.naic.org/userinfo',
    session_popup_time: 300, // 5 min
    session_idle_time: 1500 // 25 min
  }
};
